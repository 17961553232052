import React from "react"
import Footer from "../components/Footer"
import Contacts from "../components/Contacts"
import Head from "../components/Head"

//Image
import KatharinaJPG from "../static/katharina.jpg"

class Katharina extends React.Component {
  render() {
    return (
      <div>
        <Head />
        <div class="container">
          <div class="main">
            <div class="header">
              <div class="title">
                {" "}
                <a href="/">
                  <h1> </h1>
                </a>{" "}
              </div>
            </div>
            <div class="content">
              <div class="item">
                <h1>Die Autorin</h1>
                <br />
                <p class="MsoNormal">
                  <b>Katharina Gabber</b> wurde 1954 in Kleinscheuern
                  (Siebenbürgen) geboren, heiratete in Hahnbach und war nach
                  ihrer Aussiedlung 1990 nach Deutschland als ausgebildete
                  Hauswirtschafterin tätig. Während dieser Zeit hat sie ihre
                  Koch- und Backfertigkeiten weiter verfeinert und hat, neben
                  ihrer Familie, vielen Menschen Gaumenfreuden beschert. Das
                  Kochen und Backen ist ihre Leidenschaft.
                </p>
                <p class="MsoNormal">
                  {" "}
                  <img
                    src={KatharinaJPG}
                    border="0"
                    height="313"
                    width="250"
                    alt=""
                  />
                  <br />
                </p>
              </div>
              <div class="item">
                <h1>Wie kam es zu den Büchern?</h1>
                <br />
                <p class="MsoNormal">
                  Unsere Mutter Katharina hat es schon immer verstanden ihre
                  Familie kulinarisch zu verwöhnen. Ihre Back- und
                  Kochspezialitäten haben sich auch bei Freunden und Gästen der
                  Familie schnell herumgesprochen und wurden immer beliebter und
                  begehrter. Besonders die siebenbürgischen Kuchen und Gerichte
                  fanden großen Anklang. Die Idee mit dem Buch stand somit von
                  Anfang an unter einem guten Stern und diejenigen die ihre
                  Köstlichkeiten kannten, waren vom Erfolg überzeugt. Angefangen
                  mit der Enkeltochter, die vor und bei jedem Besuch bei der Oma
                  zu fragen pflegt: &#8222;Was hast du gebacken Oma?&#8220; oder
                  &#8222;Was hast du gekocht Oma?&#8220;
                  <br />
                  <br />
                  Der Erfolg des Backbuches ließ nicht lange auf sich warten und
                  damit wurde dann auch der nächste Grundstein gelegt und ein
                  neues Ziel ins Auge gefasst: ein Kochbuch sollte entstehen.
                  Wieder einmal lag viel Arbeit vor unserer Mutter. Sie ist es
                  jedoch mit viel Freude angegangen, denn diesmal hatte sie noch
                  mehr Unterstützung und zwar von den vielen zufriedenen und
                  begeisterten Kunden.
                  <br />
                  <br />
                  Noch während das Kochbuch entstand, beschloss unsere Mutter
                  ein zweites Backbuch anzufertigen, da sie noch viele
                  schmackhafte Backrezepte in ihrer Sammlung hatte, die im
                  ersten Backbuch keinen Platz mehr fanden. Das zweite Backbuch
                  setzt also fort, was sie im ersten Buch bereits begonnen hat.
                  Es findet sich auch darin eine besondere Auswahl an feinen
                  Rezepten der siebenbürgischen und deutschen Backtradition.
                </p>
                <p class="MsoNormal">
                  Um die Sammlung der Rezepte zu vervollständigen, hat Katharina
                  schließlich noch ihre besten Einweckrezepte in einem Buch
                  zusammengefasst.
                  <br />
                </p>
                <p class="MsoNormal">
                  Wir wünschen allen Freunden des Kochens und des Backens, ein
                  gutes Gelingen, einen Guten Appetit und viel Freude beim
                  Zubereiten!!
                  <br />
                  <br />
                  <b>Familie Gabber</b>
                </p>
              </div>
            </div>
            <Contacts />
            <div class="clearer">
              <span />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Katharina
